import React from "react"
import "./index.scss"

import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function ResetPasswordRequest() {
  function getInputElementValue(name: string): string {
    return (document.getElementsByName(name)[0] as HTMLInputElement).value
  }

  function handlePwResetForm(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
  }

  function handleResetPassword() {
    const email = getInputElementValue("email")

    const loginData = {
      email: email || ""
    }

    fetch("/api/auth/reset-password", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginData),
    })
      .then(async (_res) => {
        const response = await _res.json()
        toast.success(response.message)
      })
      .catch(async (e) => {
        toast.error(`An error occured: ${e}`)
      })
  }

  return (
    <div className="resetPwContainer">
      <form onSubmit={handlePwResetForm} className="inputModal">
        <div className="modalHeader">Reset Password</div>
        <div className="inputContainer">
          <label htmlFor="email">
            Email <span id="modalMessage"></span>
          </label>
          <input name="email" type="email" id="email" />
        </div>
        <div className="submitButton">
          <button onClick={handleResetPassword}>
            <i className="fa-solid fa-right-to-bracket"></i> Send Reset Link
          </button>
        </div>
      </form>
    </div>
  )
}

export default ResetPasswordRequest
