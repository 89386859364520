import React from "react"
import "./index.scss"
import siteLogo from "../../images/logo.png"

function handleDiscord() {
  window.location.replace("https://discord.gg/8g6QadTdu2")
}

function Landing() {
  return (
    <div className="landingContainer">
      <div className="siteLogo">
        <img alt="PyroCDN Logo" src={siteLogo} />
      </div>
      <div className="siteHeading">
        Welcome to <span className="siteName">PyroCDN</span>
      </div>
      <div className="siteSlogan">
        The easy solution to managing your screenshots, shortlinks and files.
        <br />
        With in-depth analytics on how they're used!
      </div>
      <div className="links">
        <a href="/login">
          <i className="fa-solid fa-right-to-bracket"></i> Sign up
        </a>
        <a onClick={handleDiscord}>
          <i className="fa-brands fa-discord"></i> Join our Discord
        </a>
      </div>
      <div className="plans">
        <div className="planCard">
          <div className="planTitle">Free</div>
          <div className="planPrice">£0 / Month</div>
          <div className="planContents">
            <div className="planItem">5GB Storage</div>
            <div className="planItem">50MB Upload Size Limit</div>
            <div className="planItem">50 Shortlinks</div>
            <div className="planItem">5 Subdomains</div>
            <div className="planItem">1 Custom Domain</div>
          </div>
          <div className="planLink">
            <a href="/login">
              <i className="fa-solid fa-right-to-bracket"></i> Get Started
            </a>
          </div>
        </div>
        <div className="planCard">
          <div className="planTitle">Premium</div>
          <div className="planPrice">£2.50 / Month</div>
          <div className="planContents">
            <div className="planItem">Unlimited Storage</div>
            <div className="planItem">250MB Upload Size Limit</div>
            <div className="planItem">∞ Shortlinks</div>
            <div className="planItem">∞ Subdomains</div>
            <div className="planItem">10 Custom Domains</div>
          </div>
          <div className="planLink">
            <a href="/login">
              <i className="fa-solid fa-cart-shopping"></i> Buy Now
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Landing
