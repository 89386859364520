import React from "react"
import "./index.scss"

function ErrorPage() {
  return (
    <div className="errorContainer">
      <div className="errorTitle">How did we get here?</div>
      <div className="errorDescription">
        Oops! What your looking for isn't here.
      </div>
      <div className="errorButtons">
        <a href="/discord">
          <i className="fa-brands fa-discord"></i> Join the discord
        </a>
        <a href="/">
          <i className="fa-sharp fa-solid fa-house"></i> Homepage
        </a>
      </div>
    </div>
  )
}

export default ErrorPage
