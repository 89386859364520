import React from "react"
import "./index.scss"

import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function Register() {
  function getInputElementValue(name: string): string {
    return (document.getElementsByName(name)[0] as HTMLInputElement).value
  }

  function handleRegisterForm(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
  }

  function handleRegister() {
    const username = getInputElementValue("username")
    const email = getInputElementValue("email")
    const password = getInputElementValue("password")

    const registerData = {
      username: username || "",
      email: email || "",
      password: password || "",
    }

    fetch("/api/auth/register", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(registerData),
    })
      .then(async (_res) => {
        const response = await _res.json()
        if (response.success) {
          toast.success("Register successful")
          setTimeout(() => {
            document.location = "/"
          }, 1000)
        } else {
          if(response.error) {
            toast.error(response.error)
          } else {
            toast.error("Invalid username, email or password")
          }
        }
      })
      .catch(async (e) => {
        toast.error(`An error occured: ${e}`)
      })
  }

  return (
    <div className="registerContainer">
      <form onSubmit={handleRegisterForm} className="inputModal">
        <div className="modalHeader">Register</div>
        <div className="inputContainer">
          <label htmlFor="username">
            Username <span id="modalMessage"></span>
          </label>
          <input name="username" type="username" id="username" />
        </div>
        <div className="inputContainer">
          <label htmlFor="email">
            Email <span id="modalMessage"></span>
          </label>
          <input name="email" type="email" id="email" />
        </div>
        <div className="inputContainer">
          <label htmlFor="password">Password</label>
          <input name="password" type="password" id="password" />
        </div>
        <div className="submitButton">
          <button onClick={handleRegister}>
            <i className="fa-solid fa-right-to-bracket"></i> Register
          </button>
        </div>
      </form>
    </div>
  )
}

export default Register
