import React, { useEffect, useState } from "react"

import "./index.scss"
import { userStats, userInfo, planLimits, UploadedFiles } from "../../types"
import { toast } from "react-toastify"

function Profile() {
  const initFiles: UploadedFiles = [
    { id: "1" },
    { id: "2" },
    { id: "3" },
    { id: "4" },
    { id: "5" },
  ]

  const initUserStats: userStats = {
    files: 0,
    totalFileSize: 0,
  }

  const initUserInfo: userInfo = {
    id: <i className="fa-solid fa-spinner"></i>,
    email: <i className="fa-solid fa-spinner"></i>,
    username: <i className="fa-solid fa-spinner"></i>,
    plan: <i className="fa-solid fa-spinner"></i>,
    api_token: <i className="fa-solid fa-spinner"></i>
  }

  const initPlanLimits: planLimits = {
    domains: 0,
    subdomains: 0,
    shortlinks: 0,
    fileSizeLimit: 0,
    storage: 0,
  }

  const [userStats, setUserStats] = useState(initUserStats)
  const [userInfo, setUserInfo] = useState(initUserInfo)
  const [planLimits, setPlanLimits] = useState(initPlanLimits)
  const [files, setFiles] = useState(initFiles)

  useEffect(() => {
    fetch("/api/profile/stats")
      .then(async (res) => {
        const userStat = await res.json()
        const userProfile = await (await fetch("/api/profile/@me")).json()
        const userLimits = await (
          await fetch(`/api/plans/${userProfile.plan}/limits`)
        ).json()
        const files = await (await fetch("/api/files/recent")).json()

        setUserStats(userStat)
        setUserInfo(userProfile)
        setPlanLimits(userLimits)
        setFiles(files)
      })
      .catch(async (e) => {
        toast.error(`An error occured: ${e}`)
      })
  }, [])

  let storageUsed: number = Number(
    (
      (Number(userStats.totalFileSize) / Number(planLimits.storage)) *
      100
    ).toFixed(2)
  )
  if (storageUsed > 100) storageUsed = 100
  useEffect(() => {
    const element = document.getElementById("progressBar") as HTMLDivElement
    const value = `${storageUsed}%`
    element.style.width = value

    const files = Array.from(document.getElementsByClassName("file loaded"))
    files.forEach((element) => {
      const ele = element as HTMLDivElement
      setTimeout(() => {
        ele.style.opacity = "1"
      }, 100)
    })
  }, [storageUsed, userStats, userInfo, planLimits, files])

  function formatBytes(bytes: number, decimals = 2) {
    if (!bytes) return "0 Bytes"

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  return (
    <div className="profileContainer">
      <div className="profileHeader">
        <div className="userInfo">
          <div key="id" className="userInfoValueContainer">
            ID: <span className="userInfoValue">{userInfo.id}</span>
          </div>
          <div key="email" className="userInfoValueContainer">
            Email: <span className="userInfoValue">{userInfo.email}</span>
          </div>
          <div key="username" className="userInfoValueContainer">
            Username: <span className="userInfoValue">{userInfo.username}</span>
          </div>
          <div key="plan" className="userInfoValueContainer">
            Plan: <span className="userInfoValue">{userInfo.plan}</span>
          </div>
          <div key="api_token" className="userInfoValueContainer">
            API Key: <span className="userInfoValue">{userInfo.api_token}</span>
          </div>
        </div>
      </div>
      <div className="fileSizeProgressBar">
        <div className="progressLabel">
          <span>
            {isNaN(storageUsed) ? 0 : storageUsed}% Storage Used{" "}
            {`(${userStats.files} files)`}
            <br />
            Used GB {`${formatBytes(Number(userStats.totalFileSize))}`} /{" "}
            {`${formatBytes(Number(planLimits.storage))}`}
          </span>
        </div>
        <div className="container">
          <div id="progressBar" className="progress"></div>
        </div>
      </div>
      <div className="profileUpdates">
        <div className="mostRecentUploads">
          <div className="containerHeader">Most recent uploads</div>
          <div className="uploads">
            {files.map((f) => {
              let style = {}
              if (f.id)
                style = {
                  backgroundImage: `url('${
                    process.env.REACT_APP_USER_CDN_HTTPS === "true" ? "https" : "http"
                  }://${userInfo.username}.${
                    process.env.REACT_APP_USER_CDN_DOMAIN
                  }/${f.id}')`,
                }

              return (
                <div key={Math.random() * 300} className="uploadPreview">
                  <div
                    onClick={() => {
                      return f.id
                        ? window.open(
                            `${
                              process.env.REACT_APP_USER_CDN_HTTPS === "true"
                                ? "https"
                                : "http"
                            }://${userInfo.username}.${
                              process.env.REACT_APP_USER_CDN_DOMAIN
                            }/${f.id}`,
                            "_blank"
                          )
                        : null
                    }}
                    style={style}
                    className={f.id ? "file loaded" : "file"}
                  >
                    {!f.id ? <i className="fa-solid fa-images"></i> : null}
                  </div>
                  <div className="fileName">
                    {f.id || "Loading..."}
                    {`${f.mime_type ? `.${f.mime_type.split("/")?.[1]}` : ""}`}
                  </div>
                </div>
              )
            })}

            <div className="uploadPreview end">
              <div
                onClick={() => {
                  window.open(`/profile/files`, "_blank")
                }}
                className="file"
              >
                <i className="fa-solid fa-image"></i>
              </div>
              <div className="fileName">View All</div>
            </div>
          </div>
        </div>
        <div className="profileAnalytics">
          <div className="containerHeader">Profile Analytics</div>
          <div className="analyticsContainer">Coming soon...</div>
        </div>
      </div>
    </div>
  )
}

export default Profile
