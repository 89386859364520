import React, { BaseSyntheticEvent } from "react"
import "./index.scss"

function Upload() {
  function handleEvent(event: BaseSyntheticEvent) {
    event.preventDefault()
    event.stopPropagation()
    console.log(event)
  }

  return (
    <div className="uploadContainer">
      <div className="pageHeader">Upload a file</div>
      <span className="seperator"></span>
      <div className="uploadInput">
        <label
          onDragEnd={handleEvent}
          onDragExit={handleEvent}
          onDragEnter={handleEvent}
          onDragLeave={handleEvent}
          onDrop={handleEvent}
          htmlFor="file"
        >
          <i className="fa-solid fa-cloud-arrow-up"></i>
          Upload your file
          <br />
          <span className="uploadNote">Drag & Drop or Click to upload</span>
        </label>
        <input hidden id="file" name="file" type="file"></input>
      </div>
      <div className="submitUpload">
        <button type="submit">Upload</button>
      </div>
    </div>
  )
}

export default Upload
