import React, {
  BaseSyntheticEvent,
  ChangeEventHandler,
  DetailedHTMLProps,
  InputHTMLAttributes,
} from "react"
import { toast } from "react-toastify"
import "./create.scss"

function DomainCreation() {
  function toggleCertUpload(event: BaseSyntheticEvent) {
    const certUploadElement = document.getElementById("certUpload")
    if (!certUploadElement) return
    if (event.target.value === "1") {
      certUploadElement.style.display = "block"
      setTimeout(() => {
        certUploadElement.style.maxHeight = "200px"
        setTimeout(() => {
          certUploadElement.style.opacity = "100%"
        }, 200)
      }, 250)
    }
    if (event.target.value === "0") {
      certUploadElement.style.opacity = "0%"
      setTimeout(() => {
        certUploadElement.style.maxHeight = "0"
        setTimeout(() => {
          certUploadElement.style.display = "none"
        }, 500)
      }, 200)
    }
  }

  async function createDomain() {
    // Get domain name
    const domainName = (
      document.getElementsByName("domainName")[0] as HTMLInputElement
    ).value

    let sslToggle = false
    document.getElementsByName("ssl").forEach((ele) => {
      const element: HTMLInputElement = ele as HTMLInputElement
      console.log("Element name", element.value, "checked", element.checked)
      if (element.value === "1" && element.checked) sslToggle = true
      else if (element.value === "0" && element.checked) sslToggle = false
    })

    let certFile = await (
      document.getElementsByName("cert")[0] as HTMLInputElement
    ).files?.[0]?.text()
    let keyFile = await (
      document.getElementsByName("key")[0] as HTMLInputElement
    ).files?.[0]?.text()

    const domainSettings = {
      domainName,
      sslToggle,
      certFile,
      keyFile,
    }

    fetch('/api/domains/@me', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(domainSettings)
    }).then(async res => {
      const _res = await res.json()
      if(res.status !== 200) {
        toast.error(_res.message)
      } else {
        setTimeout(() => {
          document.location = `/domains/${domainSettings.domainName}`
        }, 250)
        toast.success(_res.message)
      }
    })
  }

  function handleFileSelect(element: BaseSyntheticEvent) {
    const target = element.target as HTMLInputElement
    if (target.files?.[0]) {
      const spanElement = document.getElementById(
        `${target.id}FileStatus`
      ) as HTMLSpanElement
      spanElement.className = "complete"
      spanElement.innerText = "File Uploaded"
    }
  }

  return (
    <div className="domainCreationContainer">
      <div className="pageHeader">Add a domain</div>
      <span className="seperator"></span>
      <div className="valueInput">
        <div className="inputHeader">Domain Name</div>
        <input id="domainName" name="domainName"></input>
      </div>
      <div className="valueInput">
        <div className="inputHeader">SSL</div>
        <div className="inputOptions">
          <input
            onChange={toggleCertUpload}
            hidden
            name="ssl"
            type="radio"
            id="sslOn"
            value="1"
          ></input>
          <input
            onChange={toggleCertUpload}
            hidden
            name="ssl"
            type="radio"
            id="sslOff"
            value="0"
          ></input>
          <div className="inputSSLNotice">
            Please select whether or not you would like to have SSL enabled.
          </div>
          <div style={{ display: "none" }} className="inputSSLONNotice">
            Your domain will be shown as secure. You can use HTTPS
          </div>
          <div style={{ display: "none" }} className="inputSSLOFFNotice">
            Your domain will not be shown as secure. You can not use HTTPS
          </div>
          <div className="radio">
            <label htmlFor="sslOn" className="sslToggleOption">
              <span>Yes</span>
            </label>
            <label htmlFor="sslOff" className="sslToggleOption">
              <span>No</span>
            </label>
          </div>
        </div>
      </div>
      <div id="certUpload" style={{ display: "none" }} className="valueInput">
        <div className="inputHeader">SSL Certificates</div>
        <div className="inputSSLNotice">
          Please upload the SSL certificate for this domain.
          <br />A wildcard will secure all subdomains.
        </div>
        <div className="inputOptions">
          <label htmlFor="cert">
            Upload Certificate
            <br />
            <span id="certFileStatus">No file</span>
          </label>
          <label htmlFor="key">
            Upload Keyfile
            <br />
            <span id="keyFileStatus">No file</span>
          </label>
          <input
            hidden
            id="cert"
            name="cert"
            type="file"
            onChange={handleFileSelect}
            accept=".crt, .cer, .ca-bundle, .p7b, .p7c, .p7s, .pem, .txt"
          ></input>
          <input
            hidden
            id="key"
            name="key"
            type="file"
            onChange={handleFileSelect}
            accept=".key, .keystore, .jks, .txt, .pem"
          ></input>
        </div>
      </div>
      <div id="submit" className="valueInput">
        <input onClick={createDomain} type="submit"></input>
      </div>
    </div>
  )
}

export default DomainCreation
