import React, { BaseSyntheticEvent, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import "./manage.scss"

export type Subdomain = {
  name?: string
  domain_owner?: string
  ssl_enabled?: boolean
  subdomain?: boolean
  date_added?: String
  cloudflare_enabled?: boolean
  found?: boolean
}

function SubmanagedDomain() {
  const { domain, subdomain } = useParams()
  const initialSubdomainState: Subdomain = {}
  const [loading, setLoading] = useState(true)
  const [subConfig, setSubInfo] = useState(initialSubdomainState)

  useEffect(() => {
    setLoading(true)
    if (!subdomain) {
      setLoading(false)
      setSubInfo({})
    } else {
      fetch(`/api/domains/${domain}/subdomains/${subdomain}`)
        .then(async (response) => {
          const domains = await response.json()
          if (response.status === 200) domains.found = true
          if (response.status !== 200) domains.found = false
          const subdomainInfo: Subdomain = domains
          setSubInfo(subdomainInfo)
          setLoading(false)
        })
        .catch(async (e) => {
          toast.error(`An error occured: ${e}`)
        })
    }
  }, [subdomain])

  if (loading) {
    return (
      <div className="subdomainManagement loading">
        <div>Loading...</div>
      </div>
    )
  }

  if (!subdomain) {
    return (
      <div className="subdomainManagement loading">
        <div>Select a subdomain</div>
      </div>
    )
  }

  if (subConfig.found === false) {
    return (
      <div className="subdomainManagement loading">
        <div>Domain does not exist.</div>
      </div>
    )
  }

  async function uploadSSLFiles () {
    const certInput = (document.getElementById('cert') as HTMLInputElement)
    const keyInput = (document.getElementById('key') as HTMLInputElement)
    const certificate = await certInput?.files?.[0]?.text()
    const key = await keyInput?.files?.[0]?.text()

    fetch(`/api/domains/${domain}/subdomains/${subdomain}/ssl`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cert: certificate,
        key: key
      }),
    }).then(async res => {
      const response = await res.json()
      return toast.error(response?.message || 'Oops, Something went wrong')
    }).catch(err => {
      return toast.error(err?.message || 'Oops, Something went wrong')
    })
  }

  function validateCertificate () {
    const certInput = (document.getElementById('cert') as HTMLInputElement)
    const keyInput = (document.getElementById('key') as HTMLInputElement)
    const uploadButton = (document.getElementById('uploadCertificates') as HTMLInputElement)
    const certificate = certInput?.files?.[0]?.text()
    const key = keyInput?.files?.[0]?.text()

    const certLabel = (document.querySelector('[for="cert"]') as HTMLLabelElement)
    const keyLabel = (document.querySelector('[for="key"]') as HTMLLabelElement)

    if (certificate) {
      if (!certLabel.classList.contains('selected')) {
        certLabel.className += 'selected'
      }
      certLabel.innerText = 'Uploaded Certificate'
    }

    if (key) {
      if (!keyLabel.classList.contains('selected')) {
        keyLabel.className += 'selected'
      }
      keyLabel.innerText = 'Uploaded Keyfile'
    }

    if (certificate && key) {
      uploadButton.removeAttribute('disabled')
    }

  }

  function downloadSharexConfig () {
    // TODO: grab user token and current domain to put into sharex template
    var sharexTemplate = {
      "DestinationType": "ImageUploader, TextUploader, FileUploader",
      "RequestMethod": "POST",
      "RequestURL": "https://your-domain/upload",
      "Headers": {
        "key": "",
        "extension": "true"
      },
      "Body": "MultipartFormData",
      "FileFormName": "file"
    }

    return
  }

  function deleteSubdomain() {
    const confirmation = confirm(
      `Are you sure you want to delete the subdomain ${subdomain}

THIS CAN NOT BE UNDONE.`
    )
    if (confirmation) {
      fetch(`/api/domains/${domain}/subdomain/${subdomain}`, {
        method: "delete",
      })
        .then(async (response) => {
          const res = await response.json()
          if (response.status === 200) {
            toast.success(res.message)
            document.location = `/domains/${domain}`
          } else if (response.status === 401) {
            toast.error(res.message)
          } else {
            toast.error(`An error occured: ${res.message}`)
          }
        })
        .catch(async (e) => {
          toast.error(`An error occured: ${e}`)
        })
    }
  }

  return (
    <div className="subdomainManagement">
      <div className="containerHeader">
        Managing <span className="domainName">{subdomain}</span>
      </div>
      <div className="subdomainSettings">
        <div className="actions">
          {!subdomain.startsWith("*.") ? (
            <>
              <button className="domainValidationBtn">
                <i className="fa-regular fa-circle-check"></i> Verify domain
                records
              </button>
              <button
                onClick={deleteSubdomain}
                className="delete"
                value={subdomain}
              >
                <i className="fa-regular fa-trash-can"></i> Delete subdomain
              </button>
            </>
          ) : (
            <>
              <button
                onClick={downloadSharexConfig}
                className="sharexConfig"
                value={subdomain}
              >
                <i className="fa-solid fa-download"></i> Download sharex
                config
              </button>
            </>
          )}
        </div>
        {[process.env.REACT_APP_BASE_DOMAIN, "localhost"].includes(domain) ? (
          <>
            <div className="sslToggle">
              <div className="sectionHeader">
                Domains SSL managed by support@pyrocdn.com
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="sslCerts">
              <div className="sectionHeader">SSL Certificates</div>
              <div className="inputOptions">
                <label htmlFor="cert">Upload Certificate</label>
                <label htmlFor="key">Upload Keyfile</label>
                <input
                  hidden
                  id="cert"
                  type="file"
                  accept=".crt, .cer, .ca-bundle, .p7b, .p7c, .p7s, .pem, .txt"
                  onChange={validateCertificate}
                ></input>
                <input
                  hidden
                  id="key"
                  type="file"
                  accept=".key, .keystore, .jks, .txt"
                  onChange={validateCertificate}
                ></input>
                <button
                onClick={uploadSSLFiles}
                id="uploadCertificates"
                value={subdomain}
              >
                <i className="fa-solid fa-upload"></i> Upload
              </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default SubmanagedDomain
