import React, { Fragment, useEffect, useState } from "react"
import "./index.scss"
import siteLogo from "../../images/logo.png"
import { toast } from "react-toastify"

type userState = {
  loggedIn: boolean
}

const defaultUser: userState = {
  loggedIn: false,
}

function handleLogout() {
  fetch("/api/auth/logout", {
    method: "GET",
    credentials: "include"
  })
    .then(async (_res) => {
      const response = await _res.json()
      if (response.success) {
        toast.success("Logout successful")
        setTimeout(() => {
          document.location = "/"
        }, 1000)
      } else {
        toast.error("Logout not successful.")
      }
    })
    .catch(async (e) => {
      toast.error(`An error occured: ${e}`)
    })
}

function Navbar() {
  const [user, setUser] = useState(defaultUser)

  const userLoggedIn = user.loggedIn

  useEffect(() => {
    const abortController = new AbortController()
    const fetchData = async () => {
      fetch("/api/auth/check")
        .then(async (_response) => {
          const response = await _response.json()
          if (response.access) setUser({ loggedIn: true })
        })
        .catch(async (e) => {
          toast.error(`An error occured: ${e}`)
        })
    }
    fetchData()

    return () => {
      abortController.abort()
    }
  }, [userLoggedIn])

  return (
    <div className="navbar">
      <a href="/" className="logo">
        <img alt="PyroCDN Logo" src={siteLogo} />
      </a>
      <div className="links">
        <a href="/">Home</a>
        <a href="/terms">Terms</a>
        <a href="/privacy">Privacy Policy</a>
      </div>
      <div className="accountControl">
        {userLoggedIn ? (
          <Fragment>
            <a href="/upload">
              <i className="fa-solid fa-upload"></i>
            </a>
            <a href="/domains">
              <i className="fa-solid fa-globe"></i> Your Domains
            </a>
            <a href="/profile">
              <i className="fa-solid fa-user"></i> Your Profile
            </a>
            <a className=".logout" onClick={handleLogout}>
              <i className="fa-solid fa-right-from-bracket"></i>
            </a>
          </Fragment>
        ) : (
          <a href="/login">
            <i className="fa-solid fa-right-to-bracket"></i> Login
          </a>
        )}
      </div>
    </div>
  )
}

export default Navbar
