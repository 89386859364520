import React, { useEffect, useState } from "react"
import "./index.scss"

import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useLocation } from "react-router-dom"

function ResetPassword() {
  function getInputElementValue(name: string): string {
    return (document.getElementsByName(name)[0] as HTMLInputElement).value
  }

  function handlePwResetForm(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
  }

  const urlParams = new URLSearchParams(document.location.search)
  const resetPwID = urlParams.get("resetpw_key")
  const [inputsDisabled, setInputsDisabled] = useState(false)

  function handleResetPassword() {
    const newPassword = getInputElementValue("password")
    const newPasswordRepeated = getInputElementValue("passwordconfirmed")

    const resetPwData = {
      newPassword: newPassword || "",
      newPasswordRepeated: newPasswordRepeated || "",
    }

    fetch(`/api/auth/reset-password/${resetPwID}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(resetPwData),
    })
      .then(async (_res) => {
        const response = await _res.json()
        if (_res.status !== 200) {
          toast.error(response.message)
        } else {
          toast.success(response.message)
          setTimeout(() => {
            document.location = "/login"
          }, 2500)
        }
      })
      .catch(async (e) => {
        toast.error(`An error occured: ${e}`)
      })
  }

  useEffect(() => {
    if (!resetPwID) {
      document.location = "/404"
    }

    fetch(`/api/auth/reset-password/${resetPwID}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (res) => {
      const response = await res.json()
      if (res.status !== 200) {
        toast.error(response.message)
        setInputsDisabled(true)
      }
    })
  }, [])

  return (
    <div className="resetPwContainer">
      <form onSubmit={handlePwResetForm} className="inputModal">
        <div className="modalHeader">Reset Password</div>
        <div className="inputContainer">
          <label htmlFor="password">
            New Password <span id="modalMessage"></span>
          </label>
          <input
            disabled={inputsDisabled}
            name="password"
            type="password"
            id="password"
          />
          <label htmlFor="passwordconfirmed">
            Confirm New Password <span id="modalMessage"></span>
          </label>
          <input
            disabled={inputsDisabled}
            name="passwordconfirmed"
            type="password"
            id="passwordconfirmed"
          />
        </div>
        <div className="submitButton">
          <button disabled={inputsDisabled} onClick={handleResetPassword}>
            <i className="fa-solid fa-right-to-bracket"></i> Reset My Password
          </button>
        </div>
      </form>
    </div>
  )
}

export default ResetPassword
