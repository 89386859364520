import React, { Fragment, useEffect, useState } from "react"

import "./files.scss"
import BrokenIMG from "./broken.png"
import { userStats, userInfo, planLimits, UploadedFiles } from "../../types"
import { Link, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"

function FilePreview(props: {
  id: string
  extention?: string
  image?: string
}) {
  return (
    <div className="filePreview">
      <div className="file">
        {props.id !== "Loading" ? (
          <img
            loading="lazy"
            onError={(error) => {
              console.info("Image failed to load. Hiding...")
              ;(error.target as HTMLImageElement).src = BrokenIMG
              // var parentElement = (error.target as HTMLImageElement).parentElement?.parentElement
              // if (parentElement) {
              //   parentElement.style.display = "none"
              // }
            }}
            src={props.image}
            alt=""
          />
        ) : (
          <Fragment />
        )}
      </div>
      <div className="fileName">
        {props.id}
        {props.extention ? `.${props.extention}` : ""}
      </div>
      <div className="fileControls">
        <Link to="/">View</Link>
        <button className="rename">
          <i className="fa-solid fa-pen-to-square"></i>
        </button>
        <button className="delete">
          <i className="fa-solid fa-trash"></i>
        </button>
      </div>
    </div>
  )
}

function Files() {
  const initUserInfo: userInfo = {
    id: "",
    email: "",
    username: "",
    plan: "",
    api_token: "",
  }

  const initFiles: UploadedFiles = []
  for (let i = 0; i < 50; i++) {}

  const initUserStats: userStats = {
    files: 0,
    totalFileSize: 0,
  }

  const [userStats, setUserStats] = useState(initUserStats)
  const [files, setFiles] = useState(initFiles)
  const [filesLoaded, setFilesLoaded] = useState(false)
  const [userInfo, setUserInfo] = useState(initUserInfo)

  const [searchParams] = useSearchParams()
  let currentPage = 1
  const pageNum = searchParams.get("page") || 1
  if (!isNaN(Number(pageNum))) currentPage = Number(pageNum)

  useEffect(() => {
    fetch("/api/profile/stats")
      .then(async (res) => {
        const userProfile = await (await fetch("/api/profile/@me")).json()
        const userStat = await res.json()
        setUserStats(userStat)
        setUserInfo(userProfile)
        setFilesLoaded(true)
      })
      .catch(async (e) => {
        toast.error(`An error occured: ${e}`)
      })
  }, [])

  useEffect(() => {
    if (userInfo.username) {
      fetch(`/api/files/@me?page=${currentPage}`).then(async (res) => {
        const files = await res.json()
        setFiles(files)
        // setFilesLoaded(true);
      })
    }
  }, [currentPage, userInfo])

  function loadFiles() {
    setFilesLoaded(false)
  }

  function Paginator(props: {
    current: number
    pages: number
    loading?: boolean
  }) {
    let elements = [<span>Page:</span>]

    if (props.pages === -1) {
      elements = [<></>]
    } else if (props.pages < 9) {
      for (let i = 1; i <= props.pages; i++) {
        elements.push(
          <Link
            className={props.current === i ? "active" : ""}
            onClick={loadFiles}
            key={`page-${i}`}
            to={`?page=${i}`}
          >
            {i}
          </Link>
        )
      }
    } else if (props.current <= 3 && props.pages <= 9) {
      for (let i = 1; i <= 9; i++) {
        elements.push(
          <Link onClick={loadFiles} key={`page-${i}`} to={`?page=${i}`}>
            {i}
          </Link>
        )
      }
    } else if (props.current <= 3 && props.pages >= 9) {
      for (let i = 1; i <= 6; i++) {
        elements.push(
          <Link
            onClick={loadFiles}
            className={i === props.current ? "active" : ""}
            key={`page-${i}`}
            to={`?page=${i}`}
          >
            {i}
          </Link>
        )
      }
      elements.push(
        ...[
          <span key={Math.random()}>—</span>,
          <Link
            onClick={loadFiles}
            key={`page-${props.pages - 1}`}
            to={`?page=${props.pages - 1}`}
          >
            {props.pages - 1}
          </Link>,
          <Link
            onClick={loadFiles}
            key={`page-${props.pages}`}
            to={`?page=${props.pages}`}
          >
            {props.pages}
          </Link>,
        ]
      )
    } else if (props.current > 3 && props.current < props.pages - 3) {
      elements = [
        <Link onClick={loadFiles} key={`page-1`} to={`?page=1`}>
          1
        </Link>,
        <Link onClick={loadFiles} key={`page-2`} to={`?page=2`}>
          2
        </Link>,
        <span key={Math.random()}>—</span>,
        <Link
          onClick={loadFiles}
          key={`page-${props.current - 1}`}
          to={`?page=${props.current - 1}`}
        >
          {props.current - 1}
        </Link>,
        <Link
          className="active"
          key={`page-${props.current}`}
          to={`?page=${props.current}`}
        >
          {props.current}
        </Link>,
        <Link
          onClick={loadFiles}
          key={`page-${props.current + 1}`}
          to={`?page=${props.current + 1}`}
        >
          {props.current + 1}
        </Link>,
        <span key={Math.random()}>—</span>,
        <Link
          onClick={loadFiles}
          key={`page-${props.pages - 1}`}
          to={`?page=${props.pages - 1}`}
        >
          {props.pages - 1}
        </Link>,
        <Link
          onClick={loadFiles}
          key={`page-${props.pages}`}
          to={`?page=${props.pages}`}
        >
          {props.pages}
        </Link>,
      ]
    } else {
      elements = [
        <Link onClick={loadFiles} key={`page-1`} to={`?page=1`}>
          1
        </Link>,
        <Link onClick={loadFiles} key={`page-2`} to={`?page=2`}>
          2
        </Link>,
        <span key={Math.random()}>—</span>,
      ]

      for (let i = props.pages - 6; i <= props.pages; i++) {
        elements.push(
          <Link
            onClick={loadFiles}
            className={i === props.current ? "active" : ""}
            key={`page-${i}`}
            to={`?page=${i}`}
          >
            {i}
          </Link>
        )
      }
    }

    return <Fragment children={elements} />
  }

  function formatBytes(bytes: number, decimals = 2) {
    if (!bytes) return "0 Bytes"

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  return (
    <div className="filesContainer">
      <div className="paginator">
        <Paginator
          current={currentPage}
          pages={Math.ceil(Number(userStats.files) / 50) || -1}
        />
      </div>
      { !filesLoaded ? <>
        <div className="loading">Loading...</div>
      </> : files.length < 1 ? <div className="noFiles">Sorry you haven't uploaded any files yet.</div> : <></> }
      <div className="uploads">
        {files.map((file) => {
          return (
            <FilePreview
              key={file.id === "Loading" ? Math.random() : file.id}
              id={file.id}
              extention={file.mime_type ? file.mime_type.split("/")[1] : ""}
              image={
                file.id !== "Loading"
                  ? `${
                      process.env.REACT_APP_USER_CDN_HTTPS === "true"
                        ? "https"
                        : "http"
                    }://${userInfo.username}.${
                      process.env.REACT_APP_USER_CDN_DOMAIN
                    }/${file.id}`
                  : ""
              }
            />
          )
        })}
      </div>

      <div className="paginator">
        <Paginator
          current={currentPage}
          pages={Math.ceil(Number(userStats.files) / 50) || -1}
        />
      </div>
    </div>
  )
}

export default Files
