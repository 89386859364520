import React, { Fragment, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import "./index.scss"

type domainProps = {
  domain: string
  active: boolean
}

function DomainManagement() {
  function DomainElement(props: domainProps) {
    return (
      <Link to={"./" + props.domain} className="managableDomain">
        <div
          className={"domainStatus " + (props.active ? "active" : "inactive")}
        >
          {props.active ? (
            <Fragment>
              <i className="fa-solid fa-circle-check"></i>
              <label>Active</label>
            </Fragment>
          ) : (
            <Fragment>
              <i className="fa-solid fa-circle-xmark"></i>
              <label>Inactive</label>
            </Fragment>
          )}
        </div>
        <div className="domainName">{props.domain}</div>
      </Link>
    )
  }

  const [domains, setDomains] = useState([])

  const domainElements = [
    ...domains.map((d) =>
      d === "Add Domain" ? (
        <Link key={"addDomain"} to={"create"} className="managableDomainCreate">
          <div className="domainStatus active">
            <i className="fa-solid fa-circle-plus"></i>
          </div>
          <div className="domainName">{d}</div>
        </Link>
      ) : (
        <DomainElement key={d} active={true} domain={d} />
      )
    ),
  ]

  useEffect(() => {
    const abortController = new AbortController()
    const fetchData = async () => {
      fetch("/api/domains/@me")
        .then(async (_response) => {
          const response = await _response.json()
          response.push("Add Domain")
          setDomains(response)
        })
        .catch(async (e) => {
          toast.error(`An error occured: ${e}`)
        })
    }
    fetchData()

    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <div className="domainManagementContainer">
      <div className="pageHeader">Manage your domains</div>
      <div className="domainList">
        {domainElements.length > 0 ? (
          domainElements
        ) : (
          <div className="loading">Loading...</div>
        )}
      </div>
    </div>
  )
}

export default DomainManagement
