import React, {
  useEffect,
  useState,
} from "react"
import { findDOMNode } from "react-dom"
import { Link, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import ErrorPage from "../error"
import "./manage.scss"

type SubdomainProps = {
  children?: React.ReactNode // 👈️ type children
}

function ManagedDomain(props: SubdomainProps) {
  const { domain, subdomain } = useParams()
  const [loading, setLoading] = useState(true)
  const [domainExists, setDomainExists] = useState(false)
  const [subdomains, setSubdomains] = useState([])
  const [subdomainDialogOpen, setSubdomainDialogState] = useState(false)
  const [currentSubdomain, setCurrentSubdomain] = useState({
    name: null,
    domain_owner: null,
    ssl_enabled: null,
    subdomain: null,
    date_added: null,
    cloudflare_enabled: null,
  })

  function createSubdomain() {
    const subdomainGiven: string = (
      document.getElementsByName("subdomainName")?.[0] as HTMLInputElement
    ).value

    fetch(`/api/domains/${domain}/subdomains`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        domainName: `${subdomainGiven}.${domain}`,
      }),
    }).then(async (res) => {
      const _res = await res.json()
      if (res.status !== 200) toast.error(_res.message)
      else {
        setTimeout(() => {
          document.location = `/domains/${domain}/subdomain/${subdomainGiven}.${domain}`
        }, 250)
        toast.success(_res.message)
      }
    })
  }

  function toggleDialog() {
    setSubdomainDialogState(!!!subdomainDialogOpen)
  }

  useEffect(() => {
    const abortController = new AbortController()
    const fetchData = async () => {
      fetch(`/api/domains/${domain}`)
        .then(async (_response) => {
          const response = await _response.json()
          console.log(response)
          if (_response.status === 404) setDomainExists(false)
          else setDomainExists(true)

          setSubdomains(response)
          setLoading(false)

          if (subdomain) {
            const subdomainInfo = subdomains.find(
              (sd: { name: string }) => sd.name === subdomain
            )
            if (!subdomainInfo)
              return setCurrentSubdomain({
                name: null,
                domain_owner: null,
                ssl_enabled: null,
                subdomain: null,
                date_added: null,
                cloudflare_enabled: null,
              })
            setCurrentSubdomain(subdomainInfo)
          }
        })
        .catch(async (e) => {
          toast.error(`An error occured: ${e}`)
        })
    }
    fetchData()

    return () => {
      abortController.abort()
    }
  }, [])

  if (!domainExists && !loading) {
    return <ErrorPage />
  }

  function deleteDomain() {
    const confirmation = confirm(
      `Are you sure you want to delete the domain ${domain}

THIS CAN NOT BE UNDONE.`
    )
    if (confirmation) {
      fetch(`/api/domains/${domain}`, {
        method: "delete",
      })
        .then(async (response) => {
          const res = await response.json()
          if (response.status === 200) {
            toast.success(res.message)
            document.location = `/domains`
          } else if (response.status === 401) {
            toast.error(res.message)
          } else {
            toast.error(`An error occured: ${res.message}`)
          }
        })
        .catch(async (e) => {
          toast.error(`An error occured: ${e}`)
        })
    }
  }

  return (
    <>
      <div className="domainManagementContainer">
        <div className="pageHeader">
          Managing{" "}
          <span style={{ color: "var(--text-secondary)" }}>{domain}</span>
        </div>
        <span className="seperator"></span>
        <div className="domainControls">
          <div className="subdomainList">
            <div className="containerHeader">Subdomains</div>
            <div className="subdomains">
              {subdomains.map((sd: { name: string }) => {
                return (
                  <Link
                    className={sd.name === subdomain ? "active" : ""}
                    key={sd.name}
                    to={`/domains/${domain}/subdomain/${sd.name}`}
                  >
                    { sd.name.startsWith('*.') ? (<span className="primaryDomain">Primary</span>) : (null) }
                    <span className="subdomainName">{sd.name}</span>
                  </Link>
                )
              })}
            </div>
            <div onClick={toggleDialog} className="subdomainAddition">
              <i className="fa-solid fa-circle-plus"></i> Add Subdomain
            </div>
          </div>
          {props.children}
        </div>
        {subdomainDialogOpen ? (
          <>
            <div className="subdomainCreate">
              <div className="subdomainDetails">
                <div className="subContent">
                  <div className="header">
                    Enter the domain you'd like to use:
                  </div>
                  <div className="newSubdomain">
                    <input name={"subdomainName"} type={"text"} />.{domain}
                  </div>
                  <div className="submit">
                    <button onClick={createSubdomain}>Submit</button>
                    <button id="cancel" onClick={toggleDialog}>Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        {[process.env.REACT_APP_BASE_DOMAIN, "localhost"].includes(
          domain
        ) ? (
          <></>
        ) : (
          <>
            <div key="deleteDomain">
              <span onClick={deleteDomain} className="deleteDomain">
                <i className="fa-regular fa-trash-can" />
                Delete domain
              </span>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default ManagedDomain
