import React from "react"
import "./index.scss"

import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function Login() {
  function getInputElementValue(name: string): string {
    return (document.getElementsByName(name)[0] as HTMLInputElement).value
  }

  function handleLoginForm(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
  }

  function handleRegister() {
    document.location = "/register"
  }

  function handleLogin() {
    const email = getInputElementValue("email")
    const password = getInputElementValue("password")

    const loginData = {
      email: email || "",
      password: password || "",
    }

    fetch("/api/auth/login", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginData),
    })
      .then(async (_res) => {
        const response = await _res.json()
        if (response.success) {
          toast.success("Login successful")
          setTimeout(() => {
            document.location = "/"
          }, 1000)
        } else {
          toast.error("Incorrect email or password")
        }
      })
      .catch(async (e) => {
        toast.error(`An error occured: ${e}`)
      })
  }

  return (
    <div className="loginContainer">
      <form onSubmit={handleLoginForm} className="inputModal">
        <div className="modalHeader">Log in</div>
        <div className="inputContainer">
          <label htmlFor="email">
            Email <span id="modalMessage"></span>
          </label>
          <input name="email" type="email" id="email" />
        </div>
        <div className="inputContainer">
          <label htmlFor="password">Password</label>
          <input name="password" type="password" id="password" />
        </div>
        <div className="submitButton">
          <button onClick={handleLogin}>
            <i className="fa-solid fa-right-to-bracket"></i> Sign in
          </button>
          <button onClick={handleRegister} className="signup">
            <i className="fa-solid fa-right-to-bracket"></i> Register
          </button>
        </div>
        <div className="accountRecovery">
          <label>Can't log in?</label>
          <a href="/reset-password-request">Reset Password</a>
        </div>
      </form>
    </div>
  )
}

export default Login
