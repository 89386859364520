import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import ReactDOM from "react-dom/client"
import Navbar from "./components/navbar"
import "./index.css"
import Landing from "./pages/landing"
import Login from "./pages/login"
import Register from "./pages/register"
import ResetPasswordRequest from "./pages/passwordresetrequest"


import reportWebVitals from "./reportWebVitals"
import DomainManagement from "./pages/domains"
import ErrorPage from "./pages/error"
import DomainCreation from "./pages/domains/create"
import ManagedDomain from "./pages/domains/manage"

import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import SubmanagedDomain from "./pages/domains/submanage"
import Upload from "./pages/upload"
import Profile from "./pages/user"
import Files from "./pages/user/files"
import ResetPassword from "./pages/passwordreset"

const root = ReactDOM.createRoot(document.getElementById("main") as HTMLElement)

root.render(
  <React.StrictMode>
    <Navbar />
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/reset-password-request" element={<ResetPasswordRequest />} />
        <Route path="/upload" element={<Upload />} />

        <Route path="/domains">
          <Route index element={<DomainManagement />} />
          <Route path="create" element={<DomainCreation />} />
          <Route
            path=":domain"
            element={<ManagedDomain children={<SubmanagedDomain />} />}
          />
          <Route
            path=":domain/subdomain/:subdomain"
            element={<ManagedDomain children={<SubmanagedDomain />} />}
          />
        </Route>

        <Route path="/profile" element={<Profile />} />
        <Route path="/profile/files" element={<Files />} />

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
    <ToastContainer
      position="bottom-right"
      autoClose={2500}
      theme="colored"
      pauseOnFocusLoss={false}
    />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
